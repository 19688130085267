
import Vue, { PropType } from "vue";

import { Owner, Activity } from "@/interfaces";

import { mdiPlus } from "@mdi/js";

import http from "@/http";

import MyCard from "@/components/activity/MyCard.vue";

export interface DataType {
  activities: Activity[];
}

export default Vue.extend({
  components: {
    MyCard
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data(): DataType {
    return {
      activities: []
    };
  },
  computed: {
    icon() {
      return {
        mdiPlus
      };
    }
  },
  async mounted() {
    const url = "activities";

    const params = {
      owner_id: this.owner.id
    };

    const { data } = await http.get<Activity[]>(url, {
      params
    });

    this.activities = data;
  },
  methods: {
    async click(activity: Activity) {
      if (!window.confirm("本当に削除しますか?")) {
        return;
      }

      const url = `activities/${activity.id}`;

      await http.delete(url);

      this.activities = this.activities.filter(element => {
        return element.id !== activity.id;
      });
    }
  }
});
