
import Vue, { PropType } from "vue";

import MyPreview from "@/components/MyPreview.vue";

import { Activity } from "@/interfaces";
import { date } from "@/filters";

export default Vue.extend({
  components: {
    MyPreview,
  },
  filters: {
    date,
  },
  props: {
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  computed: {
    content(): string {
      if (this.activity.content) {
        return this.activity.content;
      }

      return "";
    },
  },
});
